import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  NumberInput,
  Select,
  TextInput
} from '@thedealersconcierge/components';
import {
  VehicleCondition as VehicleConditionSchema,
  VehicleLifeCycleStage as VehicleLifeCycleStageSchema,
  VehiclePrincipalPriorUse as VehiclePrincipalPriorUseSchema
} from '@thedealersconcierge/lib/codecs/schema/vehicle';
import { VehicleBodyTypeSchema } from '@thedealersconcierge/lib/codecs/tdc';
import { vinNumber } from '@thedealersconcierge/lib/codecs/validation/vinNumber';
import { getFormFieldErrorMsg } from '@thedealersconcierge/lib/utils/forms';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  VehicleBodyType,
  VehicleCondition,
  VehicleLifeCycleStage,
  VehiclePrincipalPriorUse,
  VehicleType
} from '~/__generated__/backend/zeus';
import vehicleConditionOptions from '~/config/formSelectionOptions/vehicleConditionOptions';
import vehicleLifeCycleStageOptions from '~/config/formSelectionOptions/vehicleLifeCycleStageOptions';
import { default as vehiclePrincipalPriorUseOptions } from '~/config/formSelectionOptions/vehiclePrincipalPriorUseOptions';
import {
  stringToVehicleBodyType,
  stringToVehicleCondition,
  stringToVehicleLifeCycleStage,
  stringToVehiclePrincipalPriorUse
} from '~/lib/enumMap';

/**
 * We don't include MSRP here, we don't want to display it to the customer
 */
export type GenericVehicleFormType = {
  vin: string;
  make: string;
  model: string;
  year: string;
  mileage: string;

  // This is marked with Optional (?) since we don't use it in Both form
  // The requirement will be further fine grained in Validator Below
  lifeCycleStage?: VehicleLifeCycleStage;
  stockNumber?: string;
  bodyType?: VehicleBodyType;
  condition?: VehicleCondition;
  color?: string;
  trim?: string;
  principalPriorUse?: VehiclePrincipalPriorUse;
};

export const VehicleValidationSchema = (
  t: TFunction,
  vehicleType: VehicleType
) => {
  return z
    .object({
      vin: vinNumber(t('VIN numbers must have exactly 17 characters')),
      make: z.string().min(1, t('Make is required')),
      model: z.string().min(1, t('Model is required')),
      year: z.string().min(1, t('Year is required')),
      mileage: z.string().min(1, t('Mileage is required')),
      stockNumber: z.string().optional(),
      lifeCycleStage:
        vehicleType === VehicleType.PURCHASE
          ? VehicleLifeCycleStageSchema
          : VehicleLifeCycleStageSchema.optional(),

      bodyType:
        vehicleType === VehicleType.TRADE
          ? VehicleBodyTypeSchema
          : VehicleBodyTypeSchema.optional(),
      condition:
        vehicleType === VehicleType.TRADE
          ? VehicleConditionSchema
          : VehicleConditionSchema.optional(),
      color:
        vehicleType === VehicleType.TRADE
          ? z.string().min(1, t('Color is required'))
          : z.string().optional(),

      trim: z.string().optional(),
      principalPriorUse: VehiclePrincipalPriorUseSchema.optional()
    })
    .superRefine((data, ctx) => {
      /**
       * We have to check that the principal prior use is set for used purchase vehicles
       *
       * See PV-REQIFCUS-PPU
       */
      if (
        vehicleType === VehicleType.PURCHASE &&
        data.lifeCycleStage === VehicleLifeCycleStage.IS_USED &&
        !data.principalPriorUse
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t(
            'Principal prior use is required for used purchase vehicles'
          ),
          path: ['principalPriorUse']
        });
      }
    });
};

const GenericVehicleForm: FC<{
  form: ReactFormApi<GenericVehicleFormType, ZodValidator>;
  isSubmitting: boolean;
  vehicleType: VehicleType;
  dataTestId: string;
}> = ({ form, isSubmitting, vehicleType, dataTestId }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <form.Field
        name="vin"
        validators={{
          onBlur: vinNumber(t('VIN numbers must have exactly 17 characters'))
        }}
      >
        {(field) => {
          return (
            <TextInput
              maxLength={17}
              value={field.state.value}
              label={t('VIN Number')}
              assistiveMessage={t('Type in the value')}
              placeholder={t('VIN Number')}
              required={true}
              disabled={isSubmitting}
              onChange={field.handleChange}
              onBlur={field.handleBlur}
              errorMessage={getFormFieldErrorMsg(field)}
              dataTestId={`${dataTestId}-vin-input`}
            />
          );
        }}
      </form.Field>

      <form.Field
        name="make"
        validators={{
          onBlur: z.string().min(1, t('Make is required'))
        }}
      >
        {(field) => {
          return (
            <TextInput
              value={field.state.value ?? ''}
              label={t('Make')}
              assistiveMessage={t('Make')}
              placeholder={t('Make')}
              required={true}
              disabled={isSubmitting}
              onChange={field.handleChange}
              onBlur={field.handleBlur}
              errorMessage={getFormFieldErrorMsg(field)}
              dataTestId={`${dataTestId}-make`}
            />
          );
        }}
      </form.Field>

      <form.Field
        name="model"
        validators={{
          onBlur: z.string().min(1, t('Model is required'))
        }}
      >
        {(field) => {
          return (
            <TextInput
              value={field.state.value ?? ''}
              label={t('Model')}
              assistiveMessage={t('Model')}
              placeholder={t('Model')}
              required={true}
              disabled={isSubmitting}
              onChange={field.handleChange}
              onBlur={field.handleBlur}
              errorMessage={getFormFieldErrorMsg(field)}
              dataTestId={`${dataTestId}-model`}
            />
          );
        }}
      </form.Field>

      <form.Field
        name="year"
        validators={{
          onBlur: z.string().min(1, t('Year is required'))
        }}
      >
        {(field) => {
          return (
            <TextInput
              value={field.state.value ?? ''}
              label={t('Year')}
              assistiveMessage={t('Year')}
              placeholder={t('Year')}
              required={true}
              disabled={isSubmitting}
              onChange={field.handleChange}
              onBlur={field.handleBlur}
              errorMessage={getFormFieldErrorMsg(field)}
              dataTestId={`${dataTestId}-year`}
            />
          );
        }}
      </form.Field>

      {vehicleType === VehicleType.TRADE && (
        <form.Field
          name="bodyType"
          validators={{
            onBlur: VehicleBodyTypeSchema.optional()
          }}
        >
          {(field) => {
            return (
              <Select
                options={VehicleBodyTypeSchema.options.map((o) => {
                  return {
                    value: o.value,
                    label: o.description ?? o.value
                  };
                })}
                value={field.state.value ?? ''}
                label={t('Body Type')}
                assistiveMessage={t('Select the correct option')}
                placeholder={t('E.g., Coupe')}
                required={true}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(stringToVehicleBodyType(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-bodyType-select`}
              />
            );
          }}
        </form.Field>
      )}

      {vehicleType === VehicleType.TRADE && (
        <form.Field
          name="condition"
          validators={{
            onBlur: VehicleConditionSchema.optional()
          }}
        >
          {(field) => {
            return (
              <Select
                options={vehicleConditionOptions(t)}
                value={field.state.value ?? ''}
                label={t('Condition')}
                assistiveMessage={t('Select the correct option')}
                placeholder={t('E.g., Excellent')}
                required={true}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(stringToVehicleCondition(option.value));
                }}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-condition-select`}
              />
            );
          }}
        </form.Field>
      )}

      {vehicleType === VehicleType.TRADE && (
        <form.Field
          name="color"
          validators={{
            onBlur: z.string()
          }}
        >
          {(field) => {
            return (
              <TextInput
                value={field.state.value ?? ''}
                label={t('Color')}
                assistiveMessage={t('Type in the value')}
                placeholder={t('E.g., White')}
                required={true}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-color-input`}
              />
            );
          }}
        </form.Field>
      )}

      {vehicleType === VehicleType.PURCHASE && (
        <form.Field name="trim">
          {(field) => {
            return (
              <TextInput
                value={field.state.value ?? ''}
                label={t('Trim')}
                assistiveMessage={t('Trim')}
                placeholder={t('Trim')}
                required={false}
                disabled={isSubmitting}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-trim`}
              />
            );
          }}
        </form.Field>
      )}

      <form.Field
        name="mileage"
        validators={{
          onBlur: z.string().min(1, t('Mileage is required'))
        }}
      >
        {(field) => {
          return (
            <NumberInput
              value={field.state.value}
              label={t('Mileage')}
              assistiveMessage={t('Type in the value')}
              placeholder={t('E.g., 32,004')}
              required={true}
              disabled={isSubmitting}
              onChange={field.handleChange}
              onBlur={field.handleBlur}
              errorMessage={getFormFieldErrorMsg(field)}
              dataTestId={`${dataTestId}-mileage`}
            />
          );
        }}
      </form.Field>

      {vehicleType === VehicleType.PURCHASE && (
        <form.Field
          name="lifeCycleStage"
          validators={{
            onBlur: VehicleLifeCycleStageSchema
          }}
        >
          {(field) => {
            return (
              <Select
                options={vehicleLifeCycleStageOptions(t)}
                value={field.state.value}
                label={t('Type')}
                assistiveMessage={t('Select the correct option')}
                placeholder={t('E.g., New')}
                required={true}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(
                    stringToVehicleLifeCycleStage(option.value)
                  );

                  /**
                   * If the life cycle is changing, we have to reset a potential validation error if the
                   * principal prior use hasn't been set when the life cycle was USED
                   */
                  field.form.validateAllFields('submit');
                }}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-lifeCycle-select`}
              />
            );
          }}
        </form.Field>
      )}

      {vehicleType === VehicleType.PURCHASE && (
        <form.Field
          name="principalPriorUse"
          validators={{
            onBlur: VehiclePrincipalPriorUseSchema.optional()
          }}
        >
          {(field) => {
            return (
              <Select
                options={vehiclePrincipalPriorUseOptions(t)}
                value={field.state.value ?? ''}
                label={t('Principal Prior Use')}
                assistiveMessage={t('Select the correct option')}
                placeholder={t('E.g., Rental Vehicle')}
                // Required does not work when the Field is Conditionally Rendered
                // Somehow HTML will see it as Hidden form and throw `An invalid form control is not focusable`
                // Validation is still done in refinement level on Submit
                required={false}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(
                    stringToVehiclePrincipalPriorUse(option.value)
                  );
                }}
                onBlur={field.handleBlur}
                errorMessage={getFormFieldErrorMsg(field)}
                dataTestId={`${dataTestId}-prioruse-select`}
              />
            );
          }}
        </form.Field>
      )}
    </Fragment>
  );
};

export default GenericVehicleForm;
